import { FilterDictionary } from './filters.types';

export const filterDictionary: FilterDictionary & Record<string, string> = {
  roles: 'Roles',
  search: 'Search',
  dateTo: 'Date to',
  id: 'Document ID',
  title: 'Document title',
  category: 'Category',
  dateFrom: 'Date from',
  dateRange: 'Date range',
  portfolio: 'Portfolio category',
  documentTypes: 'Document types',
  hasVideos: 'Document has videos',
  advancedSearch: 'Advanced Search',
  documentNumber: 'Document number',
  assignments: 'Assignment filters',
  installationCategory: 'Installation category',
  networkCompany: 'Network company',
  isBookmarked: 'Within my bookmarks',
  equipment: 'Product or equipment number',
  documentCategories: 'Document category',
  customer: 'Customer name or SAP number',
  productReferenceType: 'Product reference type',
  productType: 'Product type',
  installation: 'Installation name/num, project ID or IMO num.',
  serviceProductCode: 'Workshops WAMS Service Product',

  // Possible filter types
  ASI: 'Bulletin',
  CMREPORT: 'Condition Report',
  DD: 'Design Documents',
  FAT: 'Factory Acceptance Test report',
  IPI: 'Installation Planning Instructions',
  MAN: 'Manual',
  PG: 'Product Guide',
  QEHSFLASH: 'QEHS',
  SPC: 'Spare Parts Catalogue',
  SW: 'Software',
  SWR: 'Service Work Report',
  TR: 'TechReport',
  TS: 'Technical Support',
  SPE: 'Specifications',
  REP: 'Reports',
  LIS: 'Lists',
  II: 'Installation Instructions',
  DWG: 'Diagrams & Drawings',
  CD: 'Certificates & Declarations',
  DES: 'Descriptions',
};

// value:label of document subtypes
export const subTypeDictionary: Record<string, string> = {
  'Data & Specifications': 'Data & Specifications',
  'General Information': 'General Information',
  Instructions: 'Instructions',
  'Measurement Record': 'Measurement Record',
  'Service Letter': 'Service Letter',
  'Spare Parts Notice': 'Spare Parts Notice',
  'Technical Bulletin': 'Technical Bulletin',
  Budget: 'Maintenance Planning Budget Report',
  Diagnostic: 'Diagnostic Report',
  DMP: 'Dynamic Maintenance Planning Report',
  Quarterly: 'Maintenance Planning Quarterly Report',
  Brochure: 'Brochure',
  'Layout Drawing': 'Layout Drawing',
  Presentation: 'Presentation',
  'Product Guide': 'Product Guide',
  Video: 'Video',
  'Installation Manual': 'Installation Manual',
  'Performance Manual': 'Performance Manual',
  'Test Report': 'Test Report (FAT, HAT, SAT, ...)',
  'Technical FAQ': 'Technical FAQ',
  'Installation Planning Instruction': 'Installation Planning Instruction',
  Attachments: 'Attachment',
  'Data Sheet': 'Data Sheet',
  Maintenance: 'Maintenance Manual',
  Operation: 'Operation Manual',
  'Operation & Maintenance': 'Operation & Maintenance',
  'User Manual': 'User Manual',
  'Work card': 'Work card',
  'Service Work Report': 'Service Work Report',
  'Software Package': 'Software Package',
  'Software Tool': 'Software Tool',
  'Calculation Report': 'Calculation Report',
  'Damper Fluid Analysis Report': 'Damper Fluid Analysis Report',
  Declaration: 'Declaration',
  'Field Test Report': 'Field Test Report',
  'Investigation Report': 'Investigation Report',
  'Maintenance Forecasting': 'Maintenance Forecasting',
  'Measurement Report': 'Measurement Report',
  'Root Cause Analysis Report': 'Root Cause Analysis Report',
  'Site Audit Report': 'Site Audit Report',
  'Site Inspection Report': 'Site Inspection Report',
  'Technical Letter': 'Technical Letter',
  'Technical Statement': 'Technical Statement',
  'Closed TechRequest': 'Closed TechRequest',
  Certificate: 'Certificate',
  'Inventory of Hazardous Materials': 'Inventory of Hazardous Materials',
  'Materical Certificate': 'Materical Certificate',
  'Design Description': 'Design Description',
  'Function Description': 'Function Description',
  'System Description': 'System Description',
  'Circuit Diagram': 'Circuit Diagram',
  'Connection Diagram': 'Connection Diagram',
  'P&I Diagram': 'P&I Diagram',
  'Wiring Diagram': 'Wiring Diagram',
  'Arrangement Drawing (Building)': 'Arrangement Drawing (Building)',
  'Arrangement Drawing (Equipment)': 'Arrangement Drawing (Equipment)',
  'Assembly Drawing': 'Assembly Drawing',
  'Dimensional Drawing': 'Dimensional Drawing',
  'Technical Drawing': 'Technical Drawing',
  'Assembly Instruction': 'Assembly Instruction',
  'Commissioning Manual': 'Commissioning Manual',
  'Cable List': 'Cable List',
  'Consumer List': 'Consumer List',
  'Device List': 'Device List',
  'Material List': 'Material List',
  'Part List': 'Part List',
  'Setting List': 'Setting List',
  'Signal List': 'Signal List',
  'Condition Report': 'Condition Report',
  'Simulation Report': 'Simulation Report',
  'Component Specification': 'Component Specification',
  'Material Specification': 'Material Specification',
  'Technical Specification': 'Technical Specification',
};

export const defaultFiltersDictionary = {
  id: 'id',
  title: 'title',
  search: 'search',
  subtype: 'subtype',
  maintype: 'maintype',
  equipno: 'equipment',
  doc: 'documentNumber',
  portfolio: 'portfolio',
  visibility: 'visibility',
  prt: 'productReferenceType',
  producttype: 'productType',
  installation: 'installation',
  installationCategory: 'installationCategory',
  documentSet: 'documentSet',
  hasVideos: 'hasVideos',
  isBookmarked: 'isBookmarked',
  dateFrom: 'dateFrom',
  dateTo: 'dateTo',
  serviceProductCode: 'serviceProductCode',
  customer: 'customer',
  networkCompany: 'networkCompany',
  forImmediateAttention: 'forImmediateAttention',
};

export const dateInputAlertMessage = {
  fromDateError: 'Use valid "From" date in DD-MM-YYYY format',
  toDateError: 'Use valid "To" date in DD-MM-YYYY format',
  fromDateLargerError: '"To" date must be larger than "From" date',
};
