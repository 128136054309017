import './documentData.styles.scss';

import { get } from 'lodash';
import moment from 'moment';

import * as WUI from '@wartsila/ui-kit';

import { useTrackDocument } from '../documents/documents.hooks';
import { Document } from '../documents/documents.types';
import {
  filterDictionary,
  subTypeDictionary,
} from '../filters/filters.dictionary';

const DocumentDataEntry = ({
  link,
  title,
  content,
}: {
  link?: boolean;
  title?: string;
  content?: string;
}): JSX.Element => {
  // STKB-1064: new lines must show in the document data UI
  const formattedContent = content?.replace(/\n/g, '<br />');
  return (
    <div className="documentDataEntry">
      <div className="documentDataEntry__title">{title}</div>
      <div className="documentDataEntry__content">
        {link ? (
          <a target="_blank" href={content} rel="noreferrer">
            {content}
          </a>
        ) : (
          <span dangerouslySetInnerHTML={{ __html: formattedContent ?? '-' }} />
        )}
      </div>
    </div>
  );
};

const formatDate = (date: string): string => moment(date).format('DD.MM.YYYY');

export const DocumentData = ({ data }: { data: Document }): JSX.Element => {
  const document = useTrackDocument(data.id, { enabled: true });
  if (!document.data) return <WUI.Loader className="documentContent__loader" />;

  const isExternal = document.data.visibility === 'External';
  const isExternalRestricted =
    document.data.visibility === 'External + Restricted';
  const isInternalPreview = document.data.visibility === 'Internal Preview';

  const isSoftwareMainType = document.data.mainType === 'SW';
  const isDocumentFilterType = [
    'ASI',
    'MAN',
    'SPC',
    'SW',
    'FAT',
    'IPI',
    'DD',
    'TR',
    'CD',
    'DES',
    'DWG',
    'II',
    'LIS',
    'SPE',
  ].includes(document.data.mainType);
  // special REP subtypes => 'Calculation Report','Damper Fluid Analysis Report','Measurement Report','Root Cause Analysis Report','Site Audit Report','Site Inspection Report','Technical Letter','Technical Statement'
  const isSpecialREPSubtypes =
    document.data.mainType === 'REP' &&
    [
      'Calculation Report',
      'Damper Fluid Analysis Report',
      'Measurement Report',
      'Root Cause Analysis Report',
      'Site Audit Report',
      'Site Inspection Report',
      'Technical Letter',
      'Technical Statement',
    ].includes(document.data.subType);
  const isSimulationOrTestReport =
    document.data.mainType === 'REP' &&
    ['Simulation Report', 'Test Report'].includes(document.data.subType);
  const isProductGuideSubtype = document.data.subType === 'Product Guide'; // if Product Guide subtype, do not show "Document Published to Wärtsilä Online" or "Wärtsilä Online URL"

  if (
    isDocumentFilterType ||
    isSimulationOrTestReport ||
    isSpecialREPSubtypes
  ) {
    return (
      <div className="documentData">
        <div>
          <DocumentDataEntry
            title="Document Number"
            content={document.data.documentNumber}
          />
          {document.data.mainType === 'ASI' ? (
            <DocumentDataEntry
              title="Document Issue"
              content={document.data.documentIssue}
            />
          ) : null}
          <DocumentDataEntry
            title="Document Type"
            content={get(filterDictionary, document.data.mainType)}
          />
          <DocumentDataEntry
            title="Document Subtype"
            content={document.data.subType}
          />
          <DocumentDataEntry
            title="Document Category"
            content={`${document.data.visibility} ${
              document.data.visibility.search(/Internal/gi) !== -1
                ? `${
                    document.data.validFrom
                      ? `until ${document.data.validFrom}`
                      : ''
                  }`
                : ''
            } `}
          />
          <DocumentDataEntry
            title="Action Code"
            content={document.data.actionCode}
          />
          <DocumentDataEntry
            title="Product Reference Type"
            content={document.data.productReferenceTypes.join(', ')}
          />
          {document.data.sfi ? (
            <DocumentDataEntry title="SFI" content={document.data.sfi} />
          ) : null}
          {document.data.sfi ? (
            <DocumentDataEntry
              title="SFI Description"
              content={document.data.sfiDescription}
            />
          ) : null}
          {document.data.sfi ? (
            <DocumentDataEntry
              title="SFI Main Group"
              content={document.data.sfiMainGroup}
            />
          ) : null}
          {document.data.sfi ? (
            <DocumentDataEntry
              title="SFI Group"
              content={document.data.sfiGroup}
            />
          ) : null}
        </div>
        <div>
          {isExternal && isSpecialREPSubtypes ? (
            <DocumentDataEntry
              title="Assigned To Customer"
              content={
                document.data.assignedToCustomers
                  ? document.data.assignedToCustomers.join(', ')
                  : '-'
              }
            />
          ) : null}

          {document.data.serviceProductCode ? (
            <DocumentDataEntry
              title="Workshops WAMS Service Product Code"
              content={document.data.serviceProductCode}
            />
          ) : null}
          <DocumentDataEntry
            title="Internal Comment"
            content={document.data.internalComment}
          />
          {isExternal || isInternalPreview ? (
            <DocumentDataEntry
              title="Release Note"
              content={document.data.releaseNote}
            />
          ) : null}
          <DocumentDataEntry
            content={formatDate(document.data.createdAt)}
            title="Document Published to TKB"
          />
          {!isProductGuideSubtype && isExternal && document.data.updatedAt ? (
            <DocumentDataEntry
              content={
                document.data.updatedAt
                  ? formatDate(document.data.updatedAt)
                  : '-'
              }
              title="Document Published to Wärtsilä Online"
            />
          ) : null}
          <DocumentDataEntry
            link
            title="TKB URL"
            content={document.data.tkbURL}
          />

          {(isExternal || (isExternalRestricted && isSoftwareMainType)) &&
          !isProductGuideSubtype ? (
            <div>
              <DocumentDataEntry
                link
                title="Wärtsilä Online URL"
                content={document.data.wartsilaOnlineURL}
              />
              {document.data.mainType === 'ASI' && document.data.isExWorks
                ? 'The service bulletin is not shown at Wärtsilä Online for equipment in operating status Ex Works.'
                : null}
            </div>
          ) : null}
        </div>
      </div>
    );
  }

  if (document.data.subType === 'Technical FAQ') {
    return (
      <div className="documentData">
        <div>
          <DocumentDataEntry
            title="Document Number"
            content={document.data.documentNumber}
          />
          <DocumentDataEntry
            title="Document Subtype"
            content={document.data.subType}
          />
          <DocumentDataEntry
            content={document.data.visibility}
            title="Document Category"
          />
          <DocumentDataEntry
            title="Product Reference Type"
            content={document.data.productReferenceTypes.join(', ')}
          />
        </div>
        <div>
          {document.data.publishedAt ? (
            <DocumentDataEntry
              content={formatDate(document.data.publishedAt)}
              title="Document Published to TKB"
            />
          ) : null}
          {isExternal && document.data.publishedAt ? (
            <DocumentDataEntry
              content={formatDate(document.data.publishedAt)}
              title="Document Published to Wärtsilä Online"
            />
          ) : null}
          <DocumentDataEntry
            link
            title="TKB URL"
            content={document.data.tkbURL}
          />
          {isExternal ? (
            <DocumentDataEntry
              link
              title="Wärtsilä Online URL"
              content={document.data.wartsilaOnlineURL}
            />
          ) : null}
        </div>
      </div>
    );
  }

  if (document.data.subType === 'Closed TechRequest') {
    return (
      <div className="documentData">
        <div>
          <DocumentDataEntry
            title="Document Number"
            content={document.data.documentNumber}
          />
          <DocumentDataEntry
            title="Document Subtype"
            content={document.data.subType}
          />
          <DocumentDataEntry
            title="Document Category"
            content={document.data.visibility}
          />
          <DocumentDataEntry
            title="Product Reference Type"
            content={document.data.productReferenceTypes.join(', ')}
          />
        </div>
        <div>
          <DocumentDataEntry
            content={
              document.data.answered ? formatDate(document.data.answered) : ''
            }
            title="Document Published to TKB"
          />
          {isExternal ? (
            <DocumentDataEntry
              content={
                document.data.answered ? formatDate(document.data.answered) : ''
              }
              title="Document Published to Wärtsilä Online"
            />
          ) : null}
          {document.data.salesforceURL ? (
            <DocumentDataEntry
              link
              title="Salesforce URL"
              content={document.data.salesforceURL}
            />
          ) : null}
          <DocumentDataEntry
            link
            title="TKB URL"
            content={document.data.tkbURL}
          />
          {isExternal ? (
            <DocumentDataEntry
              link
              title="Wärtsilä Online URL"
              content={document.data.wartsilaOnlineURL}
            />
          ) : null}
        </div>
      </div>
    );
  }
  if (
    document.data.mainType === 'REP' &&
    [
      'Budget',
      'Diagnostic',
      'DMP',
      'Quarterly',
      'Maintenance Forecasting',
    ].includes(document.data.subType)
  ) {
    return (
      <div className="documentData">
        <div>
          <DocumentDataEntry
            title="Document Number"
            content={document.data.documentNumber}
          />
          <DocumentDataEntry
            content={get(subTypeDictionary, document.data.subType)}
            title="Document Subtype"
          />
          <DocumentDataEntry
            content={document.data.visibility}
            title="Document Category"
          />
          <DocumentDataEntry
            title="Installation"
            content={document.data.installationName}
          />
        </div>
        <div>
          <DocumentDataEntry
            title="Document Published to TKB"
            content={formatDate(document.data.publishedAt)}
          />
          {isExternal ? (
            <DocumentDataEntry
              title="Document Published to Wärtsilä Online"
              content={formatDate(document.data.publishedAt)}
            />
          ) : null}
          {document.data.salesforceURL ? (
            <DocumentDataEntry
              link
              title="Salesforce URL"
              content={document.data.salesforceURL}
            />
          ) : null}
          <DocumentDataEntry
            link
            title="TKB URL"
            content={document.data.tkbURL}
          />
          {isExternal ? (
            <DocumentDataEntry
              link
              title="Wärtsilä Online URL"
              content={document.data.wartsilaOnlineURL}
            />
          ) : null}
        </div>
      </div>
    );
  }

  if (
    document.data.mainType === 'REP' &&
    document.data.subType === 'Service Work Report'
  ) {
    return (
      <div className="documentData">
        <div>
          <DocumentDataEntry
            title="Document Number"
            content={document.data.documentNumber}
          />
          <DocumentDataEntry
            title="Document Subtype"
            content={document.data.subType}
          />
          <DocumentDataEntry
            title="Document Category"
            content={document.data.visibility}
          />
          <DocumentDataEntry
            title="Installation"
            content={document.data.installationName}
          />
          <DocumentDataEntry
            title="Equipment"
            content={document.data.equipmentDescription}
          />
        </div>
        <div>
          <DocumentDataEntry
            content={formatDate(document.data.publishedAt)}
            title="Document Published to TKB"
          />
          {isExternal ? (
            <DocumentDataEntry
              content={formatDate(document.data.publishedAt)}
              title="Document Published to Wärtsilä Online"
            />
          ) : null}
          {document.data.salesforceURL ? (
            <DocumentDataEntry
              link
              title="Salesforce URL"
              content={document.data.salesforceURL}
            />
          ) : null}
          <DocumentDataEntry
            link={Boolean(document.data.mFilesURL)}
            title="M-Files URL"
            content={document.data.mFilesURL || 'n/a'}
          />
          <DocumentDataEntry
            link
            title="TKB URL"
            content={document.data.tkbURL}
          />
          {isExternal ? (
            <DocumentDataEntry
              link
              title="Wärtsilä Online URL"
              content={document.data.wartsilaOnlineURL}
            />
          ) : null}
        </div>
      </div>
    );
  }

  return (
    <div className="documentData">
      <div>
        <DocumentDataEntry
          title="Action code"
          content={document.data.actionCode}
        />
        <DocumentDataEntry
          title="Document Created"
          content={formatDate(document.data.createdAt)}
        />
        <DocumentDataEntry
          title="Document Issue"
          content={document.data.documentIssue}
        />
        <DocumentDataEntry
          title="Document Published"
          content={formatDate(document.data.publishedAt)}
        />
        <DocumentDataEntry
          title="Internal Comment"
          content={document.data.internalComment}
        />
        <DocumentDataEntry
          title="Release Note"
          content={document.data.releaseNote}
        />
      </div>
      <div>
        <DocumentDataEntry
          link
          title="TKB URL"
          content={document.data.tkbURL}
        />
        <DocumentDataEntry
          link
          title="Wärtsilä Online URL"
          content={document.data.wartsilaOnlineURL}
        />
      </div>
    </div>
  );
};
