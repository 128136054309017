import './filters.styles.scss';

import add from 'lodash/add';
import get from 'lodash/get';
import isArray from 'lodash/isArray';
import keys from 'lodash/keys';
import omit from 'lodash/omit';
import size from 'lodash/size';
import moment from 'moment';
import * as React from 'react';
import ContentLoader from 'react-content-loader';
import { OptionsType, OptionTypeBase } from 'react-select';
import Toggle from 'react-toggle';

import * as WUI from '@wartsila/ui-kit';

import { Accordion } from '../../components/Accordion';
import { Badge } from '../../components/Badge';
import { CheckboxTree } from '../../components/CheckboxTree';
import { Label } from '../../components/Label';
import { dateInputAlertMessage, filterDictionary } from './filters.dictionary';
import { useFilterAutocompletion } from './filters.hooks';
import {
  AdvancedSearchFilter,
  AssignmentsFilter,
  AssignmentsFilterType,
  DocumentsFilter,
  FilterType,
  FilterValue,
} from './filters.types';
import { useDebounce } from './filters.utils';

// remove duplicate of FilterAsyncMultiSelect and consolidate in a single component (lower priority)
export const FilterAsyncSingleSelect = ({
  onSelect,
  selection,
  filterType,
  dependentOn,
  enabledByDefault = false,
  disableAutocomplete = false,
}: {
  filterType: FilterType;
  enabledByDefault?: boolean;
  disableAutocomplete?: boolean;
  selection: Partial<AssignmentsFilter>;
  dependentOn?: Partial<AssignmentsFilterType>[];
  onSelect: (selection: FilterValue) => void;
}): JSX.Element => {
  const [focused, setFocused] = React.useState(false);
  const [searchValue, setSearchValue] = React.useState('');
  const debouncedSearchValue = useDebounce(searchValue, 300);
  const [value, setValue] = React.useState<OptionTypeBase[]>();

  const autocompletion = useFilterAutocompletion({
    searchValue: debouncedSearchValue,
    enabledByDefault,
    dependentOn,
    filterType,
    selection,
    focused,
  });

  const selectedFilterType = get(selection, filterType);
  React.useEffect(() => {
    if (selectedFilterType) setValue(selectedFilterType);
    else setValue(undefined);
  }, [selectedFilterType]);

  const placeholder = 'Add keyword...';
  const noOptionsMessage = autocompletion.isFetching
    ? 'Searching...'
    : 'No options';

  const handleChange = (
    selectedItem: OptionTypeBase | OptionsType<OptionTypeBase> | null
  ): void => onSelect(selectedItem as FilterValue);

  return (
    <WUI.AutoComplete
      value={value}
      filterOption={null}
      onChange={handleChange}
      placeholder={placeholder}
      options={autocompletion.data}
      onInputChange={setSearchValue}
      onBlur={() => setFocused(false)}
      onFocus={() => setFocused(true)}
      isFetching={autocompletion.isFetching}
      noOptionsMessage={() => (searchValue ? noOptionsMessage : 'Start typing')}
      disabled={disableAutocomplete}
    />
  );
};

export const FilterAsyncMultiSelect = ({
  onSelect,
  selection,
  filterType,
  dependentOn,
  enabledByDefault = false,
}: {
  filterType: FilterType;
  enabledByDefault?: boolean;
  selection: Partial<AssignmentsFilter>;
  dependentOn?: Partial<AssignmentsFilterType>[];
  onSelect: (selection: FilterValue[]) => void;
}): JSX.Element => {
  const [focused, setFocused] = React.useState(false);
  const [searchValue, setSearchValue] = React.useState('');
  const debouncedSearchValue = useDebounce(searchValue, 300);
  const [value, setValue] = React.useState<OptionTypeBase[]>();

  const autocompletion = useFilterAutocompletion({
    searchValue: debouncedSearchValue,
    enabledByDefault,
    dependentOn,
    filterType,
    selection,
    focused,
  });

  const selectedFilters = get(selection, filterType);
  React.useEffect(() => {
    if (selectedFilters) setValue(selectedFilters);
    else setValue([]);
  }, [selectedFilters]);

  const placeholder = 'Add keywords...';
  const noOptionsMessage = autocompletion.isFetching
    ? 'Searching...'
    : 'No options';

  const handleChange = (
    selectedItems: OptionTypeBase | OptionsType<OptionTypeBase> | null
  ): void => {
    let filters = [];

    if (selectedItems) {
      filters = selectedItems.map((selectedItem: OptionTypeBase) => ({
        label: selectedItem.label,
        value: selectedItem.value,
        active: selectedItem.active,
        productReferenceTypeLabel: selectedItem.productReferenceTypeLabel,
      }));
    }

    return onSelect(filters);
  };

  return (
    <WUI.AutoComplete
      isMulti
      value={value}
      filterOption={null}
      onChange={handleChange}
      placeholder={placeholder}
      options={autocompletion.data}
      onInputChange={setSearchValue}
      onBlur={() => setFocused(false)}
      onFocus={() => setFocused(true)}
      isFetching={autocompletion.isFetching}
      noOptionsMessage={() => (searchValue ? noOptionsMessage : 'Start typing')}
    />
  );
};

export const FilterContentLoader = ({
  rows = 3,
}: {
  rows?: number;
}): JSX.Element => (
  <>
    {Array.from({ length: rows }, (_, row) => (
      <ContentLoader key={row} viewBox="0 0 280 130" height={130} width={300}>
        <rect x="0" y="10" rx="3" ry="3" width="280" height="10" />
        <rect x="20" y="30" rx="3" ry="3" width="240" height="10" />
        <rect x="20" y="50" rx="3" ry="3" width="190" height="10" />
        <rect x="0" y="70" rx="3" ry="3" width="280" height="10" />
        <rect x="20" y="90" rx="3" ry="3" width="220" height="10" />
        <rect x="20" y="110" rx="3" ry="3" width="100" height="10" />
      </ContentLoader>
    ))}
  </>
);

export const AssignmentFilters = ({
  grid,
  open,
  title,
  select,
  filters,
  selected,
  hiddenTitle,
  preload = [],
  dependencies,
}: {
  title: string;
  grid?: boolean;
  open?: boolean;
  hiddenTitle?: boolean;
  filters: AssignmentsFilterType[];
  preload?: AssignmentsFilterType[];
  selected: Partial<AssignmentsFilter>;
  dependencies: Partial<
    Record<AssignmentsFilterType, Partial<AssignmentsFilterType>[]>
  >;
  select: (selection: Partial<AssignmentsFilter>) => void;
}): JSX.Element => {
  const totalFilterOptions = filters.reduce((totalOptions, filterKey) => {
    const filterOptions = get(selected, filterKey);
    return isArray(filterOptions)
      ? add(totalOptions, size(filterOptions))
      : totalOptions;
  }, 0);

  return (
    <Accordion
      grid={grid}
      open={open}
      title={title}
      hiddenTitle={hiddenTitle}
      badge={<Badge>{totalFilterOptions}</Badge>}>
      {filters.map((filterType: FilterType) => (
        <div key={filterType}>
          <Label small title={get(filterDictionary, filterType)}>
            <FilterAsyncMultiSelect
              selection={selected}
              filterType={filterType}
              dependentOn={dependencies[filterType]}
              enabledByDefault={preload.includes(filterType)}
              onSelect={(selection: FilterValue[]) =>
                select({ [filterType]: selection })
              }
            />
          </Label>
        </div>
      ))}
    </Accordion>
  );
};

export const DocumentFilters = ({
  open,
  title,
  select,
  filters,
  selected,
  hiddenTitle,
  hiddenSubtypes,
  forImmediateAttention,
  showedImmediateAttention,
  toggleImmediateAttention,
}: {
  title: string;
  open?: boolean;
  selected: string[];
  hiddenTitle?: boolean;
  hiddenSubtypes?: boolean;
  filters: DocumentsFilter;
  forImmediateAttention?: boolean;
  showedImmediateAttention?: boolean;
  toggleImmediateAttention?: () => void;
  select: (selection: string[]) => void;
}): JSX.Element => (
  <Accordion
    open={open}
    title={title}
    hiddenTitle={hiddenTitle}
    badge={
      <Badge>
        {forImmediateAttention ? size(selected) + 1 : size(selected)}
      </Badge>
    }>
    {showedImmediateAttention ? (
      <label className="react-toggle-container">
        <Toggle
          checked={forImmediateAttention}
          onChange={toggleImmediateAttention}
        />
        <span>For immediate attention</span>
      </label>
    ) : null}
    <CheckboxTree
      nodes={filters}
      onChange={select}
      checked={selected}
      hiddenSubtypes={hiddenSubtypes}
    />
  </Accordion>
);

export const AdvancedFilters = ({
  open,
  title,
  serviceProductCodeOptions,
  select,
  selected,
  resetDateRange,
}: {
  title: string;
  open?: boolean;
  resetDateRange: () => void;
  serviceProductCodeOptions: FilterValue[];
  selected: Partial<AdvancedSearchFilter>;
  select: (selection: Partial<AdvancedSearchFilter>) => void;
}): JSX.Element => {
  const placeholder = 'Add keywords...';

  // We have to omit dateFrom or dateTo as it is only one filter as the user always selects the date range
  const totalFilterOptions = keys(omit(selected, 'dateFrom')).reduce(
    (totalOptions, filterKey) => {
      const filterOption = get(selected, filterKey);
      // serviceProductCode is an array of objects
      if(isArray(filterOption)){
        return add(totalOptions, size(filterOption))
      }
      return filterOption ? add(totalOptions, 1) : totalOptions;
    },
    0
  );

  const [manualModeOn, setManualModeOn] = React.useState(false);
  const [alertOn, setAlertOn] = React.useState(false);
  const [alertMessage, setAlertMessage] = React.useState('');
  // from/toDate stored in state as 'DD-MM-YYYY' format
  const [fromDate, setFromDate] = React.useState<string>(
    selected.dateFrom?.replace(/T.*/, '').split('-').reverse().join('-') || ''
  );
  const [toDate, setToDate] = React.useState<string>(
    selected.dateTo?.replace(/T.*/, '').split('-').reverse().join('-') || ''
  );

  const validateDateFormat = (stringToValidate: string): boolean => {
    // regex checks for 31 day months and 29th of feb use cases too
    const rgexp =
      /(^(((0[1-9]|1[0-9]|2[0-8])[-](0[1-9]|1[012]))|((29|30|31)[-](0[13578]|1[02]))|((29|30)[-](0[4,6,9]|11)))[-](19|[2-9][0-9])\d\d$)|(^29[-]02[-](19|[2-9][0-9])(00|04|08|12|16|20|24|28|32|36|40|44|48|52|56|60|64|68|72|76|80|84|88|92|96)$)/;
    const isValidDate = rgexp.test(stringToValidate);
    return isValidDate;
  };

  const validateAndSubmitDates = (): void => {
    if (validateDateFormat(fromDate) === false) {
      setAlertOn(true);
      setAlertMessage(dateInputAlertMessage.fromDateError);
      return;
    }
    if (validateDateFormat(toDate) === false) {
      setAlertOn(true);
      setAlertMessage(dateInputAlertMessage.toDateError);
      return;
    }
    if (moment(toDate, 'DD-MM-YYYY') < moment(fromDate, 'DD-MM-YYYY')) {
      setAlertOn(true);
      setAlertMessage(dateInputAlertMessage.fromDateLargerError);
      return;
    }
    select({
      dateFrom: moment(fromDate, 'DD-MM-YYYY').add(1, 'hours').toISOString(),
      dateTo: moment(toDate, 'DD-MM-YYYY').add(1, 'hours').toISOString(),
    });
    setAlertOn(false);
    setAlertMessage('');
  };

  const handleServiceProductCodeOptionsChange = (
    selectedItems: OptionTypeBase | OptionsType<OptionTypeBase> | null
  ): void => {
    let filters = [];

    if (selectedItems) {
      filters = selectedItems.map((seletedItem: OptionTypeBase) => ({
        label: seletedItem.label,
        value: seletedItem.value,
      }));
    }

    select({serviceProductCode: filters});
  };

  return (
    <Accordion
      open={open}
      title={title}
      badge={<Badge>{totalFilterOptions}</Badge>}>
      <Label small title={filterDictionary.id}>
        <WUI.InputField
          placeholder={placeholder}
          value={selected.id || ''}
          onChange={(event) => select({ id: event.currentTarget.value })}
        />
      </Label>

      <Label small title={filterDictionary.documentNumber}>
        <WUI.InputField
          placeholder={placeholder}
          value={selected.documentNumber || ''}
          onChange={(event) =>
            select({ documentNumber: event.currentTarget.value })
          }
        />
      </Label>
      <Label small title={filterDictionary.title}>
        <WUI.InputField
          placeholder={placeholder}
          value={selected.title || ''}
          onChange={(event) => select({ title: event.currentTarget.value })}
        />
      </Label>
      <Label small title={filterDictionary.serviceProductCode}>
      <WUI.AutoComplete 
        isMulti
        placeholder="Select..."
        options={serviceProductCodeOptions}
        value={selected.serviceProductCode||[]}
        onChange={handleServiceProductCodeOptionsChange}
      />
      </Label>
      <div className='advanced-filter-checkboxes '>
        <WUI.Checkbox
          checked={selected.hasVideos}
          label={filterDictionary.hasVideos}
          onChange={(event) =>
            select({ hasVideos: event.currentTarget.checked })
          }
        />
        <WUI.Checkbox
          checked={selected.isBookmarked}
          label={filterDictionary.isBookmarked}
          onChange={(event) =>
            select({ isBookmarked: event.currentTarget.checked })
          }
        />
      </div>
      <Label small title={filterDictionary.dateRange} />
      <label className="react-toggle-container">
        <Toggle
          checked={manualModeOn}
          onChange={() => setManualModeOn(!manualModeOn)}
        />
        <span>Manual Input Mode</span>
      </label>
      {manualModeOn ? (
        <>
          <Label small title="From" />
          <WUI.InputField
            placeholder="DD-MM-YYYY"
            type="text"
            value={fromDate}
            onChange={(e) => setFromDate(e.currentTarget.value)}
          />
          <Label small title="To" />
          <WUI.InputField
            placeholder="DD-MM-YYYY"
            type="text"
            value={toDate}
            onChange={(e) => setToDate(e.currentTarget.value)}
          />
          {alertOn ? <span className="date-alert">{alertMessage}</span> : null}

          <WUI.ButtonWrapper className="button-wrapper">
            
            <WUI.Button 
              variant={WUI.ButtonVariant.Gray}
              onClick={() => {
                setFromDate('');
                setToDate('');
                resetDateRange();
                setAlertOn(false);
                setAlertMessage('');
              }}>
              Cancel
            </WUI.Button>
            <WUI.Button onClick={validateAndSubmitDates}>Apply</WUI.Button>
          </WUI.ButtonWrapper>
        </>
      ) : (
        <>
          <WUI.DateRange
            presets
            onChange={({ endDate, startDate }) =>
              select({
                dateTo: endDate?.toISOString(),
                dateFrom: startDate?.toISOString(),
              })
            }
            placeholder="Select..."
            className="date-range-selector-overrides"
            endDate={selected.dateTo ? moment(selected.dateTo) : undefined}
            startDate={
              selected.dateFrom ? moment(selected.dateFrom) : undefined
            }
          />
          <button
            type="button"
            onClick={resetDateRange}
            className="date-range__reset">
            Reset range selection
          </button>
        </>
      )}

    </Accordion>
  );
};
